import axios from 'axios'
import {apiService} from "@/main";
import { MaterialAmount } from '@empower-platform/tracking-frontend-shared-types-js/build/materials/MaterialAmount';

export default class {
    wasteEndpoint = process.env.VUE_APP_WASTE_API_BASE_URL + '/api/v1/waste';
    userService;
    facilityService;
    facilityId;

    constructor(userService, facilityService) {
        this.userService = userService;
        this.facilityService = facilityService;
        this.setFacilityId();
    }

    setFacilityId() {
        this.facilityId = this.facilityService.getSelectedFacilityId();
    }

    async getAllTransactionRequests() {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/requests?facilityId=${this.facilityId}`);
    }

    async getInventory() {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/mass-balances?facilityId=${this.facilityId}`);
    }

    async getFacilityHistory(queryParams) {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/any?facilityId=${this.facilityId}&${queryParams}`)
    }

    async createUnverifiedReceive(inventoryItems, longitude, latitude, message, manualDateTime, files, manualDeliverer, currency) {
        return await apiService.call('POST', `${this.wasteEndpoint}/transactions/unconfirmed/receptions`, {
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                manualDeliverer,
                currency
            })
    }

    async createUnverifiedDeliver(inventoryItems, longitude, latitude, message, manualDateTime, files, manualRecipient, currency) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/unconfirmed/deliveries",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                manualRecipient,
                currency
            })
    }

    async createDeliveryTransactionRequest(type, inventoryItems, longitude, latitude, message, manualDateTime, files, targetedOrganization, targetedFacility, currency) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests",
            {
                type: type,
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                targetedOrganization,
                targetedFacility,
                currency
            })

    }

    async getTransactionRequest(requestCode) {
      return await apiService.call('GET', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "?facilityId=" + this.facilityService.getSelectedFacilityId())
    }

    async autoCancelTransactionRequest(requestCode) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/cancel",
            {
                facilityId: this.facilityService.getSelectedFacilityId()
            });
    }

    async manualCancelTransactionRequest(requestCode, cancelReason) {
        return await apiService.call('POST', `${this.wasteEndpoint}/transactions/requests/${requestCode}/manualCancel`, {
            facilityId: this.facilityService.getSelectedFacilityId(),
            cancelReason
        });
    }

    async rejectTransactionRequest(requestCode, rejectReason) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/reject",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                rejectReason
            })
    }

    async acceptTransactionRequest(requestCode, longitude, latitude, message, manualDateTime, files) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/accept",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                longitude,
                latitude,
                message,
                manualDateTime,
                files,
            })
    }

    async requestChangesOnTransactionRequest(requestCode, receivedItems, message, files) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/request-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                receivedItems,
                ...(message && { message: message }),
                files
            })
    }

  async acceptChangesOnTransactionRequest(requestCode, reasonForLoss, message) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/accept-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                missing: reasonForLoss,
                message
            }
        )
    }

  async rejectChangesOnTransactionRequest(requestCode,message) {
      return await apiService.call('POST', process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/reject-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                message
            }
        )
    }

  async cancelChangesOnTransactionRequest(requestCode, message) {
      return await apiService.call('POST',
      process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/cancel-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                message: message,
            }
        );
    }

    getBalanceName(massBalanceDefinition) {
        if (!massBalanceDefinition) return;
        let balanceName = ""
        for (let [key, value] of Object.entries(massBalanceDefinition)) {
            if (key !== 'processAmount') {
                balanceName = balanceName.concat(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                    return str.toUpperCase();
                })}: ${value} `)
            }
        }
        return balanceName
    }

    async isSerialNumberInUse(serialNo) {
        return await apiService.call('GET',
            `${this.wasteEndpoint}/transactions/serial-numbers/${serialNo}/check-in-use`)
            .then(response => response.isInUse);
    }
}
