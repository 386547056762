import axios from 'axios'
import { apiService } from '@/main'

export default class {

    constructor(facilityService) {
        this.facilityService = facilityService;
    }

    async process(inputs, outputs, longitude, latitude, message, manualDateTime, files) {
      return await apiService.call('POST',
        process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/process",
        {
            facilityId: this.facilityService.getSelectedFacilityId(),
            inputs,
            outputs,
            longitude,
            latitude,
            message,
            manualDateTime,
            files
        }).then(response => response.data);
    }
}
