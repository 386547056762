import axios from 'axios';
import {loadingService, userService} from "@/main";

export default class ApiService {
    token;
    endpoint;
    vue;

    async getEndpoint() {
        const token = await userService.getToken();
        return axios.create({
            json: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }
        });
    }

    setVue(vueInstance) {
        this.vue = vueInstance;
    }

    async call(method, resource, data) {
        loadingService.addLoadingItem();
        const endpoint = await this.getEndpoint();
        return endpoint({
            method,
            url: resource,
            data,
        })
            .then(res => {
                loadingService.removeLoadingItem();
                return res.data;
            })
            .catch(err => {
                this.handleError(err)
                throw err.response.data;
            })
    }

    handleError(error) {
        loadingService.removeLoadingItem();
        let errorString = error;
        if (error && error.response && error.response.data && error.response.data.message) {
            errorString = error.response.data.message;
        }
        this.vue.$eventBus.$emit("error", errorString);
    }
}
