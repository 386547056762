import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ErrorService from "./services/ErrorService";
import UserService from "./services/UserService";
import FacilityService from "./services/FacilityService";
import EventBusService from "./services/EventBusService";
import WasteService from "./services/WasteService";
import OrganizationService from "./services/OrganizationService";
import LoadingService from "./services/LoadingService";
import ProcessService from "./services/ProcessService";
import DepositService from "./services/DepositService";
import ApiService from "./services/ApiService";
import MessageService from "./services/MessageService";
import "./directives";
import "./plugins/init";
import store from './store';
import RollbarSetup from "@empower-platform/general-frontend-shared-rollbar-setup"
import DigitalTwinService from "./services/DigitalTwinService";
import {LaunchDarklyService} from "@empower-platform/general-frontend-shared-feature-flags";
import "@empower-platform/general-frontend-shared-firebase-setup"
import SharedFileUploadService from "@empower-platform/general-frontend-shared-upload-files";
import "@empower-platform/components-library/build/src/assets/common-properties.css"

RollbarSetup.setupRollbar(
    Vue,
    process.env.VUE_APP_EMPOWER_ENVIRONMENT,
    process.env.VUE_APP_SERVICE_VERSION,
    process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN
)

import { registerServiceWorker } from '@empower-platform/general-frontend-shared-service-worker';
import {DepositClient} from "@empower-platform/general-frontend-shared-empowerchain";

registerServiceWorker(
    process.env.NODE_ENV === 'production',
    process.env.BASE_URL
);

Vue.config.productionTip = false;
const userServiceSettings = {
    userMgmtApiBaseUrl: process.env.VUE_APP_USER_MGMT_SERVICE_API_BASE_URL,
    logToEndpoint: process.env.VUE_APP_LOGTO_URL,
    logToAppId: process.env.VUE_APP_LOGTO_APP_ID,
    appUrl: process.env.VUE_APP_TRACKING_APP_URL,
    logToResource: process.env.VUE_APP_LOGTO_RESOURCE,
    returnUrlParam: 'destination'
}

export const userService = new UserService(userServiceSettings, EventBusService, {
    configurePerson: RollbarSetup.configurePerson
});
export const facilityService = new FacilityService(userService, EventBusService);
export const loadingService = new LoadingService();
Vue.prototype.$sharedFileUploadService = new SharedFileUploadService();
export const wasteService = new WasteService(userService, facilityService);
export const organizationService = new OrganizationService(userService);
export const processService = new ProcessService(facilityService);
export const depositService = new DepositService(userService, facilityService, EventBusService);
export const apiService = new ApiService();
export const messageService = new MessageService(store);
export const digitalTwinService = new DigitalTwinService(EventBusService)
export const launchDarklyService = new LaunchDarklyService(EventBusService, process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_KEY)
export const depositClient = new DepositClient(process.env.VUE_APP_EMPOWER_ENVIRONMENT)

Vue.prototype.$eventBus = EventBusService;
Vue.prototype.$userService = userService;
Vue.prototype.$facilityService = facilityService;
Vue.prototype.$loadingService = loadingService;
Vue.prototype.$wasteService = wasteService;
Vue.prototype.$organizationService = organizationService;
Vue.prototype.$processService = processService;
Vue.prototype.$depositService = depositService;
Vue.prototype.$messageService = messageService;
Vue.prototype.$digitalTwinService = digitalTwinService;
Vue.prototype.$launchDarklyService = launchDarklyService;
Vue.prototype.$depositClient = depositClient;


userService.initUserService().then(() => {
    const vueInstance = new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
    Vue.prototype.$errorService = new ErrorService(vueInstance, EventBusService);
    apiService.setVue(vueInstance);
});
