import axios from 'axios'
import { firestoreInvoker, signInWithCustomToken } from "@empower-platform/general-frontend-shared-firebase-setup";
import { initAuth, isAuthenticated, signIn, signOut, fetchUserInfo, getAccessToken, getIdTokenClaims, UserScope, callbackHandler } from '@empower-platform/auth'
import { UserMgmtService } from '@empower-platform/auth'
import { loadingService } from "@/main";

const firestoreConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    firebaseEmulator: process.env.VUE_APP_FIREBASE_EMULATOR
}

const { auth } = firestoreInvoker(firestoreConfig);

export default class extends UserMgmtService {
    eventBus;
    userInfo;
    organizationId;
    rollbarConfig;

    constructor(settings, eventBus, rollbarConfig) {
        super(settings)
        this.eventBus = eventBus;
        this.rollbarConfig = rollbarConfig;
        // TODO: Now userInfo is a promise that never resolves or fails.
        this.userInfo = new Promise((resolve, reject) => {
            this.userInfoResolve = resolve;
            this.userInfoReject = reject;
        })
    }

    async initUserService() {
        try {
            await this.updateUserInfo()
        } catch (error) {
            this.eventBus.$emit('error', "Something went wrong while fetching your credentials");
            await this.logout()  
        }
    }

    async firebaseLogin() {
        const authHeaders = await this.getAuthHeaders()
            .then(response => signInWithCustomToken(auth, response.data.firebaseCustomToken))
    }

    async logout() {
        await super.logout()
        this.userinfo = null;
        await auth.signOut();
        this.eventBus.$emit('loggedOut');
        if (window.analytics) {
            window.analytics.reset();
        }
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    // TODO: Fetch logto user data from proxy, then we can fully convert this method
    // Need the comments to understand how to convert it later
    async updateUserInfo() {
        // const authHeaders = await this.getAuthHeaders()
        const promises = []
        // promises.push(this.firebaseLogin())
        if (await this.isLoggedIn()) {
            const user = await this.fetchUserInfo()
            const organizationId = await this.getOrganizationId()
            const { premigrationUserData } = user.custom_data

            this.rollbarConfig.configurePerson(
                user.sub,
                user.email,
                process.env.VUE_APP_EMPOWER_ENVIRONMENT
            )
            this.eventBus.$emit('userChanged', {
                id: user.sub,
                firstName: premigrationUserData.firstName,
                lastName: premigrationUserData.lastName,
                email: user.email,
                organizationId: organizationId,
                isDelterraPilot: undefined // TODO, its in the organization object
            });

            this.organizationId = organizationId;
        }
        // promises.push(axios.get(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + this.userId, authHeaders).then(response => {
        //     const userInfo = response.data;
        //     this.userInfoResolve(userInfo);
        //     this.userInfo = Promise.resolve(userInfo);
        //     const organizationId = userInfo.organization ? userInfo.organization.organizationId : undefined
        //     const isDelterraPilot = userInfo.organization ? userInfo.organization.isDelterraPilot : undefined
        //     this.organizationId = organizationId;
        //     this.rollbarConfig.configurePerson(
        //         this.userId,
        //         userInfo.email,
        //         process.env.VUE_APP_EMPOWER_ENVIRONMENT
        //     )
        //     this.eventBus.$emit('userChanged', {
        //         id: this.userId,
        //         firstName: userInfo.firstName,
        //         lastName: userInfo.lastName,
        //         email: userInfo.email,
        //         organizationId,
        //         isDelterraPilot
        //     });
        // org_empower_environment: import.meta.env.VITE_EMPOWER_ENVIRONMENT,

        //     if (window.analytics) {
        //         const userOrganization = userInfo.organization;
        //         const rollbar = RollbarSetup.getRollbarInstance()
        //         if (!userOrganization) {
        //             rollbar.error("No org found for user", userInfo)
        //         } else if (!userOrganization.organizationId) {
        //             rollbar.error("No orgId found for user", userInfo)
        //         } else if (!userOrganization.orgName) {
        //             rollbar.error("No orgName found for user", userInfo)
        //         }
        //         const showIntercom = this._getBrowserWidth() > 1019;
        //         const userRoles = this.getUserRoles();
        //         const mainUserRole = userRoles && userRoles.length > 0 ? userRoles[0].roleName : undefined;
        //         window.analytics.identify(this.userId, {
        //             email: userInfo.email,
        //             name: userInfo.firstName,
        //             mainUserRole: mainUserRole,
        //             empowerEnvironment: process.env.VUE_APP_EMPOWER_ENVIRONMENT,
        //             orgId: userOrganization ? userOrganization.organizationId : undefined,
        //             orgName: userOrganization ? userOrganization.orgName : undefined,
        //             phoneNumber: userInfo.phoneNumber
        //         }, {
        //             Intercom: { hideDefaultLauncher: !showIntercom }
        //         });
        //         if (userOrganization) {
        //             window.analytics.group(userOrganization.organizationId, {
        //                 name: userOrganization.orgName,
        //                 /** Does not follow naming convention, but remains due to unknown use in Intercom **/
        //                 org_empower_environment: process.env.VUE_APP_EMPOWER_ENVIRONMENT,
        //             });
        //         }
        //     }
        // }));

        return Promise.all(promises);
    }

    /**
     * @typedef {Object} UserProfile
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} phoneNumber
     * @property {?string} profilePictureUrl
     *
     */

    /**
     * This function updates a user.
     * TODO: Should be moved to the shared UserMgmtService when it has support for fetch
     *
     * @param {UserProfile} userUpdateObj - The user profile object to update.
     * @returns {Promise<UserProfile>} A Promise that resolves to the updated user profile.
     */
    async updateUser(userUpdateObj) {
        const userId = await this.getUserId()
        const getAuthHeaders = await this.getAuthHeaders()
        
        return axios.patch(process.env.VUE_APP_USER_MGMT_SERVICE_API_BASE_URL + `/users/${userId}/data`,
            {
                firstName: userUpdateObj.firstName,
                lastName: userUpdateObj.lastName,
                phoneNumber: userUpdateObj.phoneNumber,
                profilePictureUrl: userUpdateObj.profilePictureUrl,
            }, getAuthHeaders
        )
    }

    //TODO: This returns a never-resolving promise. Don't use it until we fix it
    getUserInfo() {
        return this.userInfo;
    }

    /**
     * This function gets the user name from the user ID.
     * TODO: Should be moved to the shared UserMgmtService when it has support for fetch
     *
     * @param {string} userId - The user ID.
     * @returns {Promise<string>} A Promise that resolves to the user name.
     */
    async getUserNameFromId(userId) {
        const authHeaders = await this.getAuthHeaders()
        const response = await axios.get(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + userId, authHeaders)
        return `${response.data.firstName} ${response.data.lastName}`;
    }
}
