import Cookies from "js-cookie";
import {apiService, messageService} from "@/main";

export default class {
    facilityEndpoint = process.env.VUE_APP_FACILITY_API_BASE_URL + '/api/v1/facility';
    userService;
    eventBus;
    chosenFacilityId;


    constructor(userService, eventBus) {
        this.userService = userService;
        this.eventBus = eventBus;
        this.chosenFacilityId = undefined;

        const cookieFacilityId = Cookies.get("facilityId");
        if (cookieFacilityId) {
            this.chosenFacilityId = cookieFacilityId;
        }

        eventBus.$on("loggedOut", () => {
            this.removeSelectedFacility();
        })
    }

    async selectFacility(facilityId) {
        this.chosenFacilityId = facilityId;
        Cookies.set("facilityId", this.chosenFacilityId);
    }

    removeSelectedFacility() {
        this.chosenFacilityId = undefined;
        Cookies.remove("facilityId");
        messageService.closeAblyClient();
    }


    hasSelectedFacility() {
        return typeof this.chosenFacilityId !== "undefined";
    }

    getSelectedFacilityId() {
        return this.chosenFacilityId;
    }

    async getFacilityInfo(facilityId = null) {
        const facilityToFind = facilityId || this.chosenFacilityId
        return await apiService.call('GET', `${this.facilityEndpoint}/facilities/${facilityToFind}`);
    }

    async getRoleBasedUserFacilities() {
        const organizationId = this.userService.organizationId;
        if (!organizationId) {
            alert('TODO: Organizations are not ported to Logto yet.');
            throw new Error("User has no organization id");
        }
        const response = await apiService.call('GET', `${this.facilityEndpoint}/facilities/role-based?organizationId=${organizationId}`);
        return response.facilities;
    }

    async getOtherOrganizationsFacilities(organizationId) {
        const response = await apiService.call('GET', `${this.facilityEndpoint}/facilities/${organizationId}/minimal`);
        return response.facilities;
    }

    async getMaterialCategoryList() {
        return await apiService.call('GET', `${this.facilityEndpoint}/materialtypes/active/${this.chosenFacilityId}`);
    }

    async getMaterialCategoryListDelterra() {
        return await apiService.call('GET', `${this.facilityEndpoint}/materialtypes/delterra/active/${this.chosenFacilityId}`);
    }

    async getDepositToken(facilityId = null) {
        const facilityToFind = facilityId || this.chosenFacilityId
        return (await apiService.call('GET', `${this.facilityEndpoint}/facilities/${facilityToFind}/deposit-token`)).token;
    }

    async getSuppliersList(facilityId) {
        const response = await apiService.call('GET', `${this.facilityEndpoint}/partnersettings/${facilityId}/suppliers`)
        return response.suppliers;
    }

    async getRecipientsList(facilityId) {
        const response = await apiService.call('GET', `${this.facilityEndpoint}/partnersettings/${facilityId}/off-takers`)
        return response.offTakers;
    }
}
